import React, { useEffect } from 'react';
import ProfilePic from '../images/profile_pic_1.jpg';
import { type } from '../helper_functions/typewriter.js';

function Hero() {

	// part of typewriter effect
	const titles = ['Software Engineer.', 'Full-stack Developer.', 'tech enthusiast.'];
	// calling type function for typewriter effect once page has loaded
	useEffect(() => {
		type(titles);
	});

	return (
		<section id='home' className='h-screen bg-stone-100 dark:bg-gray-900 px-12'>
			<div className='flex flex-col-reverse lg:flex-row justify-center items-center h-full gap-5 lg:gap-10'>
				<div className='flex flex-col gap-6 text-center lg:text-left'>
					<h1 className='text-3xl xs:text-5xl md:text-6xl font-semibold dark:text-stone-200'>Hi, I'm Milad Rezaee.</h1>
					<h1 className='text-lg xs:text-xl md:text-2xl dark:text-stone-100'>A <span id='typewriter' className='text-blue-500 text-xl xs:text-2xl md:text-4xl px-1 sm:px-2'>Software Engineer.</span></h1>
					<div className='flex justify-center lg:justify-start flex-col xs:flex-row gap-4 xs:gap-0 mt-6 lg:mt-2'>
						<a href='#contact'>
							<button className='border border-blue-500 dark:border-blue-700 rounded-md px-4 py-2 xs:px-6 xs:py-3 text-md xs:text-lg md:text-xl uppercase font-bold bg-blue-500 dark:bg-blue-700 text-white dark:text-stone-100 shadow-xl dark:shadow-lg shadow-blue-300 dark:shadow-blue-800 hover:bg-blue-700 dark:hover:bg-blue-600 hover:scale-[1.1] transition-all duration-200'>
								Contact Me
							</button>
						</a>
						<a href='#portfolio'>
							<button className='px-4 py-2 xs:px-6 xs:py-3 text-md xs:text-lg md:text-xl uppercase font-bold text-blue-500 hover:text-blue-700 dark:hover:text-blue-400 transition-all duration-200'>
								View My Portfolio
							</button>	
						</a>
					</div>
				</div>
				<div className='flex w-72 xs:w-96'>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="120%" id="blobSvg" style={{ opacity: "1"}}>
						<image x="0" y="30" width="100%" height="100%" clipPath="url(#shape)" href={ ProfilePic } preserveAspectRatio="xMidYMin slice" />
						<clipPath id="shape">
							<path id="blob" fill="url(#gradient)">
								<animate attributeName="d" dur="10000ms" repeatCount="indefinite" values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;" />
							</path>
						</clipPath>
					</svg>
				</div>
			</div>
		</section>
	)
}

export default Hero;