import React from 'react';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import AboutMe from './components/AboutMe';
import MyProjects from './components/MyProjects';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';

function App() {
	return (
		<>
			<NavBar />
			<Hero />
			<AboutMe />
			<MyProjects />
			<ContactMe />
			<Footer />
		</>
	)
}

export default App;