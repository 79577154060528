import React from 'react';

function TechStackTools({ tool1, tool2 }) {
	return (
		<div className='flex gap-2 hover:scale-[1.1] transition-all duration-200'>
			<img src={ tool1.img } alt='tool1' title={ tool1.name } className='w-16 h-16 rounded-xl' />
			<img src={ tool2.img } alt='tool2' title={ tool2.name } className='w-16 h-16 rounded-xl' />
		</div>
	)
}

export default TechStackTools;