import React, { useState } from 'react';
import { Carousel } from 'flowbite-react';

function ProjectCard({ project, projectNumber }) {

	const [slideOnHover, setSlideOnHover] = useState(false);

	const projectImg = (
		<div className={`overflow-hidden col-span-3 h-[190px] md:h-[362px] w-[90%] md:w-[580px] mx-6 my-6 rounded-xl border dark:border-gray-800 shadow-2xl ${projectNumber % 2 === 0 ? '' : 'xl:order-last'}`}>
			{project.slideshowOrientation === "scrollDown" ? 
				<a href={project.links.demo} target='_blank' rel='noreferrer'>
					<img src={project.imgs[0]} alt='project screenshot' className='hover:-translate-y-[89.8%] transition-all ease-linear duration-[20000ms] dark:brightness-90' />
				</a>
				:
				<Carousel slide={slideOnHover} slideInterval={2000}>
					{project.imgs.map((image, index) => {
						return (
							<a href={project.links.demo} key={index} target='_blank' rel='noreferrer'>
								<img src={image} alt='project screenshots' onMouseOver={() => setSlideOnHover(true)} onMouseLeave={() => setSlideOnHover(false)} className='dark:brightness-90' />
							</a>
						)
					})}
				</Carousel>
			}
		</div>
	)

	const projectDesc = (
		<div className='col-span-2 max-w-md px-6 py-6 flex flex-col items-center justify-center gap-4'>
			<h2 className='text-lg font-bold dark:text-stone-200'>{project.name}</h2>
			<p className='text-sm xs:text-base text-center text-stone-600 dark:text-gray-400'>{project.desc}</p>
			<div className='flex flex-wrap justify-center w-full'>
				{project.tech_stack.map((tool, index) => {
					return (
						<div key={index} className='flex items-center gap-2 border px-4 py-2 mx-2 mt-2 bg-white hover:bg-black/10 dark:hover:bg-black/10 dark:bg-gray-700 dark:border-gray-900 dark:text-stone-200 shadow-lg rounded-lg text-sm xs:text-base transition duration-200'>
							{tool.name}
							<img src={tool.img} alt='tool illustrated' className='w-6' />
						</div>
					)
				})}
			</div>
			<div className='flex gap-8 text-lg pt-6 pb-6 xl:pb-0'>
				<a href={project.links.github} target='_blank' rel='noreferrer' className='flex font-medium text-base xs:text-lg gap-2 hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>
					Code
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-brand-github">
						<path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
					</svg>
				</a>
				<a href={project.links.demo} target='_blank' rel='noreferrer' className='flex font-medium text-base xs:text-lg gap-2 hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>
					Live Demo
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-external-link">
						<path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
						<path d="M11 13l9 -9"></path>
						<path d="M15 4h5v5"></path>
					</svg>
				</a>
			</div>
		</div>
	)

	return (
		<div className={`grid grid-cols-1 xl:grid-cols-5 justify-items-center ${projectNumber % 2 === 0 ? "xl:justify-items-start" : "xl:justify-items-end"} border max-w-5xl rounded-xl shadow-2xl dark:bg-gray-800 dark:border-gray-800`}>
			{projectImg}
			{projectDesc}
		</div>
	)
}

export default ProjectCard;