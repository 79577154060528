import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ConfirmationAlert from './ConfirmationAlert';

function ContactMe() {

	const [confirmationAlert, setConfirmationAlert] = useState(false);

	function sendEmail(e) {
		e.preventDefault();

		// prod
		emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID)
			.then(() => {
				setConfirmationAlert(!confirmationAlert);
				setTimeout(() => window.location.reload(), 2000)
			}, (error) => {
				console.error(error);
			});
		
		// testing
		// setConfirmationAlert(!confirmationAlert);
		// setTimeout(() => window.location.reload(), 1500)
	}

	return (
		<section className='relative flex flex-col items-center py-20 big:py-36 px-12 dark:bg-gray-800'>
			<span id='contact' className='absolute top-[-4rem] xs:top-[0rem]'></span>
			{confirmationAlert ? 
				<ConfirmationAlert />
				:
				null
			}
			<h1 className='text-2xl big:text-3xl font-bold text-blue-500 big:pb-8'>Contact</h1>
			<div className='grid big:grid-cols-2 gap-12 py-12 max-w-6xl w-full'>
				<iframe title='google maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184551.9097725508!2d-79.54286573875535!3d43.718370958103165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1697570042890!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-xl w-full h-96 big:h-full shadow-2xl dark:brightness-90' />
				<div>
					<h1 className='text-2xl big:text-4xl font-bold py-5 dark:text-stone-200'>Let's connect!</h1>
					<form className='flex flex-col gap-4' onSubmit={sendEmail}>
						<input type='text' name='from_name' placeholder='Name*' required className='rounded border-slate-300 bg-blue-100 dark:bg-blue-200' />
						<div className='flex flex-col xs:flex-row gap-3'>
							<input type='email' name='from_email' placeholder='Email*' required className='rounded border-slate-300 xs:w-1/2 bg-blue-100 dark:bg-blue-200' />
							<input type='tel' name='from_phone' placeholder='Phone' className='rounded border-slate-300 xs:w-1/2 bg-blue-100 dark:bg-blue-200' />							
						</div>
						<textarea rows='7' name='message' placeholder='Your message...*' required className='rounded border-slate-300 bg-blue-100 dark:bg-blue-200' />
						<input type='submit' value='Send Message' className='xs:self-start px-6 py-3 rounded border border-blue-500 dark:border-blue-700 bg-blue-500 dark:bg-blue-700 text-white hover:bg-blue-700 dark:hover:bg-blue-600 transition-all duration-200 uppercase font-bold cursor-pointer' />
					</form>
				</div>
			</div>
		</section>
	)
}

export default ContactMe;