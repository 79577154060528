let words = [];
let i = 0;
let j = 0;
let currentWord = '';
let isDeleting = false;

function type(titles) {
	words = titles
	animate_typewriter();
}

function animate_typewriter() {
	currentWord = words[i];
	if (isDeleting) {
		document.getElementById('typewriter').textContent = currentWord.substring(0, j-1);
		j--;
		if (j === 0) {
			isDeleting = false;
			i++;
			if (i === words.length) {
				i = 0;
			}
		}
	} else {
		document.getElementById('typewriter').textContent = currentWord.substring(0, j+1);
		j++;
	}
	if (j === currentWord.length) {
		isDeleting = true;
		setTimeout(animate_typewriter, 1000);
	} else {
		setTimeout(animate_typewriter, 100);
	}
}

export { type };