import React, { useEffect, useState } from 'react';
import LinkedInLogoColor from '../images/linkedin_logo_color.png';
import GitHubLogo from '../images/github_logo.png';
import ResumeIcon from '../images/resume_icon.png';

function NavBar() {

	const [sidebar, setSidebar] = useState(false)

	// dark mode
	const [theme, setTheme] = useState(localStorage.theme);
	const oldTheme = theme === 'dark' ? 'light' : 'dark';

	useEffect(() => {
		const root = window.document.documentElement;
		root.classList.remove(oldTheme);
		root.classList.add(theme);

		// save new config to localStorage
		localStorage.setItem('theme', theme);
		console.log(localStorage.theme)
	}, [theme, oldTheme])

	return (
		<nav className='fixed flex justify-around xs:justify-between w-full gap-4 px-12 py-8 bg-white dark:bg-slate-700 shadow-lg z-10'>
			{ /* Mobile: Sidebar */
				sidebar ?
					<div className='fixed w-full h-full top-0 left-0 z-20'>
						<div className='w-full flex justify-end'>
							<button onClick={() => setSidebar(!sidebar)} className='relative right-6 top-10 z-20 transition hover:delay-75 hover:text-blue-500 dark:text-stone-200 dark:hover:text-blue-500'>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
									<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>	
						</div>
						<div className='absolute top-0 left-0 h-screen w-screen flex flex-col justify-center items-center gap-16 bg-white dark:bg-gray-900 z-[4]'>
							<div className='flex flex-col items-center'>
								<a href="#home" className='text-2xl my-3 transition duration-200 hover:text-blue-500 dark:text-stone-200 dark:hover:text-blue-500' onClick={() => setSidebar(!sidebar)}>
									Home
								</a>
								<a href="#about" className='text-2xl my-3 transition duration-200 hover:text-blue-500 dark:text-stone-200 dark:hover:text-blue-500' onClick={() => setSidebar(!sidebar)}>
									About
								</a>
								<a href="#portfolio" className='text-2xl my-3 transition duration-200 hover:text-blue-500 dark:text-stone-200 dark:hover:text-blue-500' onClick={() => setSidebar(!sidebar)}>
									Portfolio
								</a>
								<a href="#contact" className='text-2xl my-3 transition duration-200 hover:text-blue-500 dark:text-stone-200 dark:hover:text-blue-500' onClick={() => setSidebar(!sidebar)}>
									Contact
								</a>
							</div>
							<ul className='flex items-center gap-5'>
								<a href='https://resume.creddle.io/resume/2gv8c6a2nvu' target='_blank' rel='noreferrer'><img src={ResumeIcon} alt='Resume' className='w-7 hover:scale-[1.2] transition duration-200 dark:invert' />
								</a>
								<a href='https://www.linkedin.com/in/milad-rezaee/' target='_blank' rel='noreferrer'>
									<img src={LinkedInLogoColor} alt='LinkedIn' className='w-7 mb-px brightness-0 hover:brightness-100 hover:scale-[1.2] transition duration-200 dark:invert dark:hover:invert-0' />
								</a>
								<a href='https://github.com/MiladRez' target='_blank' rel='noreferrer'>
									<img src={GitHubLogo} alt='GitHub' className='w-7 hover:scale-[1.2] transition duration-200 dark:invert' />
								</a>
							</ul>
						</div>
					</div>
				: null
			}
			<div className='flex'>
				<h1 className='text-3xl font-bold select-none dark:text-stone-200'>MiladRez<span className='text-blue-500'>.dev</span></h1>
				<ul className='hidden sm:flex items-center gap-5 ml-8'>
					<a href='https://resume.creddle.io/resume/2gv8c6a2nvu' target='_blank' rel='noreferrer'><img src={ResumeIcon} alt='Resume' className='w-7 hover:scale-[1.2] transition duration-200 dark:invert' />
					</a>
					<a href='https://www.linkedin.com/in/milad-rezaee/' target='_blank' rel='noreferrer'>
						<img src={LinkedInLogoColor} alt='LinkedIn' className='w-7 mb-px brightness-0 hover:brightness-100 hover:scale-[1.2] transition duration-200 dark:invert dark:hover:invert-0' />
					</a>
					<a href='https://github.com/MiladRez' target='_blank' rel='noreferrer'>
						<img src={GitHubLogo} alt='GitHub' className='w-7 hover:scale-[1.2] transition duration-200 dark:invert' />
					</a>
				</ul>
			</div>
			<div className='flex gap-4'>
				<ul className='hidden big:flex items-center gap-4 text-lg pt-2'>
					<a href='#home' className='hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>Home</a>
					<a href='#about' className='hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>About</a>
					<a href='#portfolio' className='hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>Portfolio</a>
					<a href='#contact' className='hover:text-blue-500 transition-all duration-200 dark:text-stone-200 dark:hover:text-blue-500'>Contact</a>
				</ul>
				<div className='flex items-center px-2 xs:pl-4 xs:pr-0 xs:pt-1.5'>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="1 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1 dark:invert">
						<path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
					</svg>
					<label className="relative inline-flex cursor-pointer">
						<input type="checkbox" value="" className="sr-only peer" checked={theme === 'dark' ? true : false} onChange={() => setTheme(theme === 'light' ? 'dark' : 'light')} />
						<div className="w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1 -2 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-1 dark:invert">
							<path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
						</svg>
					</label>
				</div>
			</div>
			<div className="big:hidden">
				{/* Mobile: Hamburger menu icon */}
				<button onClick={() => setSidebar(!sidebar)} className="px-3 py-2 rounded bg-blue-500 dark:bg-blue-700 hover:bg-blue-700 dark:hover:bg-blue-600 transition-all duration-200">
					{/* <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg> */}
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="2 2 20 20" strokeWidth={2.2} stroke="white" className="w-5 h-5">
						<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
					</svg>
				</button>
			</div>
		</nav>
	)
}

export default NavBar;