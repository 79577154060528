import React from 'react';

function ConfirmationAlert() {
	return (
		<div className='fixed top-0 w-full h-full flex items-center justify-center backdrop-blur bg-white/70 z-10 dark:bg-black/70'>
			<h1 className='text-2xl dark:text-stone-200'>Thank you for your message!</h1>
		</div>
	)
}

export default ConfirmationAlert;