import React from 'react';
import ProfilePicToronto from '../images/profile_pic_2.png';
import JavaScriptLogo from '../images/javascript_logo.png';
import TypeScriptLogo from '../images/typescript_logo.png';
import NodeJSLogo from '../images/node-js_logo.png';
import MongoDBLogo from '../images/mongodb_logo.png';
import ReactJSLogo from '../images/react_logo.png';
import NextJSLogo from '../images/nextjs_logo.png';
import HTML5Logo from '../images/html-5_logo.png';
import CSS3Logo from '../images/css-3_logo.png';
import TailwindCSSLogo from '../images/tailwind_logo.png';
import SemanticUILogo from '../images/semantic_ui_logo.png';
import TechStackTools from './TechStackTools';

const techStack = [
	[
		{ name: "JavaScript", img: JavaScriptLogo },
		{ name: "TypeScript", img: TypeScriptLogo }
	],
	[
		{ name: "NodeJS", img: NodeJSLogo },
		{ name: "MongoDB", img: MongoDBLogo }
	],
	[
		{ name: "ReactJS", img: ReactJSLogo },
		{ name: "NextJS", img: NextJSLogo }
	],
	[
		{ name: "HTML5", img: HTML5Logo },
		{ name: "CSS3", img: CSS3Logo }
	],
	[
		{ name: "Tailwind CSS", img: TailwindCSSLogo },
		{ name: "Semantic UI", img: SemanticUILogo }
	]
]

function AboutMe() {

	return (
		//span is for anchor tag links alignment
		<section className='relative px-12 py-20 sm:py-36 dark:bg-gray-800'>
			<span id='about' className='absolute top-[-4rem] xs:top-[-2rem]'></span>
			<div className='flex flex-col big:flex-row justify-center items-center gap-12'>
				<img src={ ProfilePicToronto } alt='profile pic 2' className='w-[20rem] h-[22rem] rounded-3xl object-cover object-top shadow-2xl border dark:border-none dark:brightness-90' />
				<div className='max-w-lg flex flex-col gap-4 justify-center text-center big:text-start'>
					<h2 className='text-2xl font-bold text-blue-500'>About Me</h2>
					<h3 className='text-2xl sm:text-3xl dark:text-stone-200'>An <span className='text-blue-500'>ambitious</span> software developer and tech enthusiast. </h3>
					<p className='text-stone-600 pt-4 big:pt-0 text-sm sm:text-base dark:text-gray-400'>
						I am a software engineer based in Toronto, Canada with a passion for building modern,
						responsive web applications and providing great digital experiences. With a background
						in both front-end and back-end development, my expertise lies in creating simple but
						engaging interfaces with a focus on cross platform functionality and user experience.
						I am always learning and honing my skills by utilizing the latest development tools
						and techniques in the industry and I am actively looking for opportunities to showcase my potential.</p>
				</div>
			</div>
			<div className='flex gap-8 justify-center items-center pt-24'>
				<div className='hidden xl:flex gap-3 items-center pt-1'>
					<h3 className='text-xl font-mon dark:text-stone-200'>tech_stack =</h3><span className='text-4xl font-mono dark:text-stone-200'>{"{"}</span>
				</div>
				<div className='grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8'>
					{techStack.map((tools, index) => {
						return <TechStackTools key={index} tool1={tools[0]} tool2={tools[1]} />
					})}
				</div>
				<span className='hidden xl:flex text-4xl font-mono dark:text-stone-200'>{"}"}</span>
			</div>
		</section>
	)
}

export default AboutMe;