import React from "react";
import LinkedInLogoColor from '../images/linkedin_logo_color.png';

function Footer() {
	return (
		<footer className="relative flex flex-col justify-center items-center pt-28 pb-12 bg-slate-700">
			<button className="absolute top-[-30px] xs:right-32 bg-blue-500 dark:bg-blue-700 rounded-full px-3 py-3 hover:bg-blue-700 hover:scale-[1.1] transition-all duration-200" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth", })}>
				<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="2 1 20 20" id="chevron-force-up" fill="white" className="rotate-180">
					<path d="M6.25753788,13.2424621 C5.84748737,12.8324116 5.84748737,12.1675884 6.25753788,11.7575379 C6.66758839,11.3474874 7.33241161,11.3474874 7.74246212,11.7575379 L12,16.0150758 L16.2575379,11.7575379 C16.6675884,11.3474874 17.3324116,11.3474874 17.7424621,11.7575379 C18.1525126,12.1675884 18.1525126,12.8324116 17.7424621,13.2424621 L12.7424621,18.2424621 C12.3324116,18.6525126 11.6675884,18.6525126 11.2575379,18.2424621 L6.25753788,13.2424621 Z M6.25753788,7.24246212 C5.84748737,6.83241161 5.84748737,6.16758839 6.25753788,5.75753788 C6.66758839,5.34748737 7.33241161,5.34748737 7.74246212,5.75753788 L12,10.0150758 L16.2575379,5.75753788 C16.6675884,5.34748737 17.3324116,5.34748737 17.7424621,5.75753788 C18.1525126,6.16758839 18.1525126,6.83241161 17.7424621,7.24246212 L12.7424621,12.2424621 C12.3324116,12.6525126 11.6675884,12.6525126 11.2575379,12.2424621 L6.25753788,7.24246212 Z"></path>
				</svg>
			</button>
			<div className="flex flex-col items-center gap-12 big:gap-24">
				<h1 className='text-2xl font-bold select-none text-slate-200'>MiladRez<span className='text-blue-500'>.dev</span></h1>
				<div className='flex gap-6'>
					<a href='https://www.linkedin.com/in/milad-rezaee/' target='_blank' rel='noreferrer'>
						<img src={LinkedInLogoColor} alt='LinkedIn' className='w-7 hover:brightness-100 transition duration-200' />
					</a>
					<a href='https://github.com/MiladRez' target='_blank' rel='noreferrer'>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 1 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-brand-github">
							<path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
						</svg>
					</a>
				</div>
				<p className="text-xs text-slate-200">All rights reserved &copy; 2023 <span className='font-bold'>Milad Rezaee</span></p>	
			</div>
		</footer>
	)
}

export default Footer;